// extracted by mini-css-extract-plugin
export var ClientsDataPortfoliioRevamp = "MobileClients-module--ClientsDataPortfoliioRevamp--f0e44";
export var bannerHeadingPortfolioRevamp = "MobileClients-module--bannerHeadingPortfolioRevamp--de2ac";
export var buton = "MobileClients-module--buton--50667";
export var cardContentPortfolio = "MobileClients-module--cardContentPortfolio--03ef7";
export var cardHeader = "MobileClients-module--cardHeader--fabe9";
export var cardWrapperPortFolios = "MobileClients-module--cardWrapperPortFolios--6051f";
export var cardWrapperShieldPortfolio = "MobileClients-module--cardWrapperShieldPortfolio--fb9c1";
export var detailsLink = "MobileClients-module--detailsLink--28de5";
export var disabled = "MobileClients-module--disabled--22c5f";
export var flipcard = "MobileClients-module--flipcard--3097a";
export var flipcardback = "MobileClients-module--flipcardback--d686c";
export var flipcardfront = "MobileClients-module--flipcardfront--803e2";
export var flipcardinner = "MobileClients-module--flipcardinner--cf3a2";
export var icon = "MobileClients-module--icon--a5028";
export var modalBodytestiRevamp = "MobileClients-module--modalBodytestiRevamp--ecec9";
export var modalContentTestiRevamp = "MobileClients-module--modalContentTestiRevamp--385af";
export var portfolioBtn = "MobileClients-module--portfolioBtn--fa0a9";
export var spanCardHeader = "MobileClients-module--spanCardHeader--084ce";
export var spanCardHeaderColorChange = "MobileClients-module--spanCardHeaderColorChange--7bcc2";
export var tag = "MobileClients-module--tag--c61b8";
export var text = "MobileClients-module--text--1e1cf";