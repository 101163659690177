// extracted by mini-css-extract-plugin
export var ClientsDataPortfoliioRevamp = "Clients-module--ClientsDataPortfoliioRevamp--031a9";
export var bannerHeadingPortfolioRevamp = "Clients-module--bannerHeadingPortfolioRevamp--7bf16";
export var buton = "Clients-module--buton--c32cd";
export var cardContentPortfolio = "Clients-module--cardContentPortfolio--58047";
export var cardHeader = "Clients-module--cardHeader--4bf17";
export var cardWrapperPortFolios = "Clients-module--cardWrapperPortFolios--c5371";
export var detailsLink = "Clients-module--detailsLink--c4d1c";
export var disabled = "Clients-module--disabled--87418";
export var flipcard = "Clients-module--flipcard--0189a";
export var flipcardback = "Clients-module--flipcardback--91cc8";
export var flipcardfront = "Clients-module--flipcardfront--20d2f";
export var flipcardinner = "Clients-module--flipcardinner--59c5d";
export var icon = "Clients-module--icon--f99dc";
export var modalBodytestiRevamp = "Clients-module--modalBodytestiRevamp--ee89e";
export var modalContentTestiRevamp = "Clients-module--modalContentTestiRevamp--d7aec";
export var portfolioBtn = "Clients-module--portfolioBtn--6def9";
export var spanCardHeader = "Clients-module--spanCardHeader--96b3c";
export var spanCardHeaderColorChange = "Clients-module--spanCardHeaderColorChange--38e35";
export var tag = "Clients-module--tag--6637a";
export var text = "Clients-module--text--f2e17";