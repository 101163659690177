import { Link } from "gatsby"
import React, { useState } from "react"
import * as styles from "./Clients.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Modal } from "react-bootstrap"

const Clients = ({ strapiData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenSheild, setIsModalOpenSheild] = useState(false)
  const [isModalOpenStorageChain, setIsModalOpenStorageChain] = useState(false)
  const handleVideoIconClick = () => {
    setIsModalOpen(true)
  }
  const handleVideoIconClickSheild = () => {
    setIsModalOpenSheild(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  const handleCloseModalSheild = () => {
    setIsModalOpenSheild(false)
  }
  const handleCloseModalStorageChain = () => {
    setIsModalOpenStorageChain(false)
  }
  const newPrtfolio = [
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000006969_a47e359bb9.webp",
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/nymcard_854cd064ec.webp",
      title: "Nymcard",
      description:
        "APIs and launch solutions like Prepaid cards, Multi-currency, Charge and Credit cards, and Buy Now Pay Later.",
      caseStudyTags: ["Banking", "Financial Service", "Fintech", "Payments"],
      button: "/portfolio/nymcard/",
      button2: "Dubai, UAE",
    },
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000006969_2_62e2c308b6.webp",
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/freshprep_79a39da76f.webp",
      title: "Freshprep",
      description:
        "A meal kit company aimed at promoting healthy eating raised $21M in series B funding",
      caseStudyTags: ["Agriculture", "Food Delivery", "Hospitality", "Retail"],
      button: "/portfolio/freshprep/",
      button2: "Vancouver, BC",
    },
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000006969_3_ad45259c56.webp",
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/analog_8080cf5aa9.webp",
      title: "Analog",
      description:
        "Analog is a secure dApp interface with interoperable cross-chain apps built on the Time-chain, a layer-0 blockchain transitioning from DPoS to PoT.",
      caseStudyTags: ["Blockchain", "Crypto", "Fintech", "Ecosystem"],
      // button: "Coming Soon",
      button2: "Hong Kong",
    },
  ]
  const newPrtfolioCapital = [
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000006969_4_3ef2ebcb7c.webp",
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/r_930_87336c549f.webp",
      title: "R-930 Capital",
      description:
        "R-930 Capital is a DeFi staking platform that provides 3-tiered financing and partnership guidance for startups.",
      caseStudyTags: ["Decentralized Finance", "Crypto", "Blockchain"],
      // button: "Coming Soon",
      button2: "---------",
    },
  ]
  const cards = [
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Theraforge_7bdb9b3533.webp",
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/theraforge_logo_4b5a828c89.webp",
      title: "Theraforge",
      description:
        "Hippocrates Tech is the world’s first multi-cloud digital health laboratory.",
      caseStudyTags: ["Healthcare", "Digital Health", "Health"],
      button: "/portfolio/theraforge/",
    },
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Big_Shooter_Golf_eed0212526.webp",
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/big_shooter_golf_logo_59df9de628.webp",
      title: "BigShooterGolf",
      description:
        "Big Shooter Golf combines video games and fantasy sports leagues.",
      caseStudyTags: ["On-Demand", "Simulation Game", "Gaming"],
      button: "/portfolio/big-shooter-golf/",
    },
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/App_Work_f0868cc715.webp",
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/appwork_logo_6a471535f0.webp",
      title: "AppWork",
      description:
        "AppWork simplifies work order creation and management solutions.",
      caseStudyTags: ["On-Demand", "CRM", "PropTech", "BI"],
      button: "/portfolio/appwork/",
    },
  ]
  const PortfolioCards = [
    {
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/column_logo_7e211dd7a3.webp",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Column_19fb5137b3.webp",
      title: "Column",
      description:
        "Public notice platform helps journalists, governments, and legal services.",
      portfolioTags: ["Online Media", "Public Information", "Journalism"],
      button: "/portfolio/column/",
    },
    {
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/homepie_logo_14dce3c300.webp",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Homepie_d0664a9554.webp",
      title: "Homepie",
      description: "Build an Online Marketplace to Connect Buyers & Sellers.",
      portfolioTags: ["Real Estate", "Marketplace", "e-Commerce"],
      button: "/portfolio/homepie/",
    },
    {
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/global_reader_logo_ce54edd706.webp",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/global_reader_8544fc0ce6.webp",
      title: "GlobalReader",
      description: "Real-time Data for Tracking Production Capacity.",
      portfolioTags: ["Industrial", "Manufacturing", "IT"],
      button: "/portfolio/globalreader/",
    },
  ]
  const column = [
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Stitch_Health_b249f96c53.webp",
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/stitch_logo_8d5f23dfde.webp",
      title: "Stitch.Health",
      description: "Central Messaging Platform for Healthcare Providers.",
      columnTags: ["Healthcare", "Internet", "AI ChatBot"],
      button: "/portfolio/stitch-health/",
    },
  ]
  const shield = [
    {
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Sheild_Republic_1c13fd0e63.webp",
      img2: "https://invozone-backend.s3.us-east-1.amazonaws.com/sheild_republic_logo_ea729b9cfd.webp",
      title: "Shield Republic",
      description:
        "ECommerce Platform for reinventing the customer experience.",
      sheildTags: ["Ecommerce", "Apparel", "Marketplace"],
      button: "/portfolio/shield-republic/",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.ClientsDataPortfoliioRevamp}>
        <Container>
          <Row className={` gap-30 ${styles.cardWrapperPortFolios}`}>
            {newPrtfolio?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.flipcard} tabIndex="0">
                  <div className={styles.flipcardinner}>
                    <div className={styles.flipcardfront}>
                      <img
                        src={e?.img2}
                        alt={e?.title}
                        style={{ height: "77px", width: "231px" }}
                      />
                      {/* <StaticImage
                        src={e?.img2}
                        alt={e?.title}
                        placeholder="blurred"
                        decoding="async"
                        loading="lazy"
                      /> */}
                      <hr />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.description,
                        }}
                      />
                    </div>
                    <div className={styles.flipcardback}>
                      <img
                        src={e?.img}
                        alt={e?.title}
                        style={{ height: "214px", width: "345px" }}
                      />
                      {/* <StaticImage
                        src={e?.img}
                        alt={e?.title}
                        placeholder="blurred"
                        decoding="async"
                        loading="lazy"
                      /> */}
                      <div className={styles.cardContentPortfolio}>
                        <div className={`  ${styles.cardHeader}`}>
                          <h2>{e?.title}</h2>
                        </div>
                        {e.caseStudyTags.map((tagPortfilio, index) => (
                          <span key={index} className={styles.tag}>
                            {tagPortfilio}
                          </span>
                        ))}
                        <div className={styles.detailsLink}>
                          {e?.button ? (
                            <Link to={e.button} className={styles.portfolioBtn}>
                              View Project Details
                            </Link>
                          ) : (
                            <button className={` ${styles.disabled}`} disabled>
                              Coming Soon
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row className={` gap-30 ${styles.cardWrapperPortFolios}`}>
            <Col lg={8} xl={8} md={12} xs={12}>
              <div className={styles.shieldTopBannerImg}>
                <img
                  className={styles.sheildImgTop}
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_100000s7007_4e21842c2e.png"
                  alt="Chris Dominguez"
                  style={{ height: "486px", width: "862px" }}
                />
                <button
                  href="#"
                  onClick={() => setIsModalOpenStorageChain(true)}
                  className={styles.buton}
                >
                  <img
                    src={
                      "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_d99dfad8cf.png"
                    }
                    alt="Play"
                    className={styles.icon}
                    style={{ height: "22px", width: "35px" }}
                  />
                  <span className={styles.text}>Play Video</span>
                </button>
              </div>
            </Col>
            {newPrtfolioCapital?.map((e, i) => (
              <Col
                lg={4}
                xl={4}
                md={12}
                xs={12}
                key={i}
                className={` gap-30 d-flex ${styles.sheildCol_4}`}
              >
                <div className={styles.flipcard} tabIndex="0">
                  <div className={styles.flipcardinner}>
                    <div className={styles.flipcardfront}>
                      {/* <StaticImage
                        src={e?.img2}
                        alt=""
                        placeholder="blurred"
                        decoding="async"
                        loading="lazy"
                      /> */}
                      <img
                        src={e?.img2}
                        alt={e?.title}
                        loading="lazy"
                        decoding="async"
                        style={{ height: "77px", width: "231px" }}
                      />
                      <hr />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.description,
                        }}
                      />
                    </div>
                    <div className={styles.flipcardback}>
                      <img
                        src={e?.img}
                        alt={e?.title}
                        loading="lazy"
                        decoding="async"
                        style={{ height: "214px", width: "345px" }}
                      />
                      {/* <StaticImage
                        src={e?.img}
                        alt=""
                        placeholder="blurred"
                        decoding="async"
                        loading="lazy"
                      /> */}
                      <div className={styles.cardContentPortfolio}>
                        <div className={`  ${styles.cardHeader}`}>
                          <h2>{e?.title}</h2>
                        </div>
                        {e.caseStudyTags.map((sheildTags, index) => (
                          <span key={index} className={styles.tag}>
                            {sheildTags}
                          </span>
                        ))}
                        <div className={styles.detailsLink}>
                          {e?.button ? (
                            <Link to={e.button} className={styles.portfolioBtn}>
                              View Project Details
                            </Link>
                          ) : (
                            <button className={` ${styles.disabled}`} disabled>
                              Coming Soon
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row className={` gap-30 ${styles.cardWrapperPortFolios}`}>
            {PortfolioCards?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.flipcard} tabIndex="0">
                  <div className={styles.flipcardinner}>
                    <div className={styles.flipcardfront}>
                      <img
                        src={e?.img2}
                        alt={e?.title}
                        style={{ height: "77px", width: "231px" }}
                      />
                      <hr />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.description,
                        }}
                      />
                    </div>
                    <div className={styles.flipcardback}>
                      <img
                        src={e?.img}
                        alt={e?.title}
                        style={{ height: "214px", width: "345px" }}
                      />

                      <div className={styles.cardContentPortfolio}>
                        <div className={`  ${styles.cardHeader}`}>
                          <h2>{e?.title}</h2>
                        </div>
                        {e.portfolioTags.map((tagPortfilio, index) => (
                          <span key={index} className={styles.tag}>
                            {tagPortfilio}
                          </span>
                        ))}
                        <div className={styles.detailsLink}>
                          <Link to={e?.button} className={styles.portfolioBtn}>
                            View Project Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row className={` gap-30 ${styles.cardWrapperPortFolios}`}>
            {column?.map((e, i) => (
              <Col
                lg={4}
                xl={4}
                md={12}
                xs={12}
                key={i}
                className={` gap-30 d-flex ${styles.columnCol_4}`}
              >
                <div className={styles.flipcard} tabIndex="0">
                  <div className={styles.flipcardinner}>
                    <div className={styles.flipcardfront}>
                      <img
                        src={e?.img2}
                        alt={e?.title}
                        loading="lazy"
                        decoding="async"
                        style={{ height: "77px", width: "231px" }}
                      />
                      <hr />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.description,
                        }}
                      />
                    </div>
                    <div className={styles.flipcardback}>
                      <img
                        src={e?.img}
                        alt={e?.title}
                        loading="lazy"
                        decoding="async"
                        style={{ height: "214px", width: "345px" }}
                      />

                      <div className={styles.cardContentPortfolio}>
                        <div className={`  ${styles.cardHeader}`}>
                          <h2>{e?.title}</h2>
                        </div>
                        {e.columnTags.map((columnTags, index) => (
                          <span key={index} className={styles.tag}>
                            {columnTags}
                          </span>
                        ))}
                        <div className={styles.detailsLink}>
                          <Link to={e?.button} className={styles.portfolioBtn}>
                            View Project Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
            <Col lg={8} xl={8} md={12} xs={12}>
              <div className={styles.columnTopBannerImg}>
                <img
                  className={styles.columnImgTop}
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/kinde_video_testimonials_93c92e6f98_bea7f325ec.webp"
                  alt="Kinde"
                  style={{ height: "486px", width: "862px" }}
                />
                <button
                  href="#"
                  onClick={() => handleVideoIconClick()}
                  className={styles.buton}
                >
                  <img
                    src={
                      "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_d99dfad8cf.png"
                    }
                    alt="play"
                    style={{ height: "22px", width: "35px" }}
                    className={styles.icon}
                  />
                  <span className={styles.text}>Play Video</span>
                </button>
              </div>
            </Col>
          </Row>
          <Row className={` gap-30 ${styles.cardWrapperPortFolios}`}>
            {cards?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.flipcard} tabIndex="0">
                  <div className={styles.flipcardinner}>
                    <div className={styles.flipcardfront}>
                      <img
                        src={e?.img2}
                        alt={e?.title}
                        loading="lazy"
                        decoding="async"
                        style={{ height: "77px", width: "231px" }}
                      />
                      <hr />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.description,
                        }}
                      />
                    </div>
                    <div className={styles.flipcardback}>
                      <img
                        src={e?.img}
                        alt={e?.title}
                        loading="lazy"
                        decoding="async"
                        style={{ height: "214px", width: "345px" }}
                      />

                      <div className={styles.cardContentPortfolio}>
                        <div className={`  ${styles.cardHeader}`}>
                          <h2>{e?.title}</h2>
                        </div>
                        {e.caseStudyTags.map((caseStudyTags, index) => (
                          <span className={styles.tag}>{caseStudyTags}</span>
                        ))}
                        <div className={styles.detailsLink}>
                          <Link to={e?.button} className={styles.portfolioBtn}>
                            View Project Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row className={` gap-30 ${styles.cardWrapperPortFolios}`}>
            <Col lg={8} xl={8} md={12} xs={12}>
              <div className={styles.shieldTopBannerImg}>
                <img
                  className={styles.sheildImgTop}
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/sheild_republic_video_testimonials_1323cdb7f2_d30db654bc.webp"
                  alt="Ryan Carter"
                  style={{ height: "486px", width: "862px" }}
                />
                <button
                  href="#"
                  onClick={() => handleVideoIconClickSheild()}
                  className={styles.buton}
                >
                  <img
                    src={
                      "https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_d99dfad8cf.png"
                    }
                    alt="play"
                    className={styles.icon}
                    style={{ height: "22px", width: "35px" }}
                  />
                  <span className={styles.text}>Play Video</span>
                </button>
              </div>
            </Col>
            {shield?.map((e, i) => (
              <Col
                lg={4}
                xl={4}
                md={12}
                xs={12}
                key={i}
                className={` gap-30 d-flex ${styles.sheildCol_4}`}
              >
                <div className={styles.flipcard} tabIndex="0">
                  <div className={styles.flipcardinner}>
                    <div className={styles.flipcardfront}>
                      <img
                        src={e?.img2}
                        alt={e?.title}
                        loading="lazy"
                        decoding="async"
                        style={{ height: "77px", width: "231px" }}
                      />
                      <hr />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.description,
                        }}
                      />
                    </div>
                    <div className={styles.flipcardback}>
                      <img
                        src={e?.img}
                        alt={e?.title}
                        loading="lazy"
                        decoding="async"
                        style={{ height: "214px", width: "345px" }}
                      />

                      <div className={styles.cardContentPortfolio}>
                        <div className={`  ${styles.cardHeader}`}>
                          <h2>{e?.title}</h2>
                        </div>
                        {e.sheildTags.map((sheildTags, index) => (
                          <span key={index} className={styles.tag}>
                            {sheildTags}
                          </span>
                        ))}
                        <div className={styles.detailsLink}>
                          <Link to={e?.button} className={styles.portfolioBtn}>
                            View Project Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Modal
        show={isModalOpen}
        onHide={handleCloseModal}
        keyboard={false}
        centered
        size="lg"
        contentClassName={styles.modalContentTestiRevamp}
      >
        <Modal.Body className={styles.modalBodytestiRevamp}>
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/eGinGdGXdGA?si=RyFeh0vrfexzxpTU&autoplay=1`}
            allowFullScreen
            title="YouTube video player"
            frameborder="0"
            allow="autoplay"
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={isModalOpenSheild}
        onHide={handleCloseModalSheild}
        keyboard={false}
        centered
        size="lg"
        contentClassName={styles.modalContentTestiRevamp}
      >
        <Modal.Body className={styles.modalBodytestiRevamp}>
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/ETvJcozwwzM?si=593q6eg9VhyVIm4B&autoplay=1`}
            allowFullScreen
            title="YouTube video player"
            frameborder="0"
            allow="autoplay"
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={isModalOpenStorageChain}
        onHide={handleCloseModalStorageChain}
        keyboard={false}
        centered
        size="lg"
        contentClassName={styles.modalContentTestiRevamp}
      >
        <Modal.Body className={styles.modalBodytestiRevamp}>
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/SeBRvwbCuks?si=PBFkID4nfnforjxP&autoplay=1`}
            allowFullScreen
            title="YouTube video player"
            frameborder="0"
            allow="autoplay"
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default Clients
