import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
const Banner = ({ strapiData }) => {
  return (
    <div className={styles.portfolioNewRevampBanner}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <div className={styles.Heading}>
            <h1 className={styles.bannerHeading}>
              Over 10+ Years Of <br /> Consistently Delivering Excellence
            </h1>
            <p>
              500+ Professionals | 300+ Projects Completed | 97% Success Rate
            </p>
          </div>
        </Row>
        <img
          src="https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_Line_a1a9a7229d.png"
          className={styles.arrowLine}
          style={{ height: "70px", width: "1280px" }}
        />
      </Container>
    </div>
  )
}

export default Banner
